import React, { useState } from 'react';

const TableContext = React.createContext({
  status: '',
  handleStatusChange: () => {},
  setStatus: (status: string) => {},
});

const StatusProvider = ({ children }) => {
  const [status, setStatus] = useState('');

  const handleStatusChange = () => {
    setStatus(status);
  };

  return (
    <TableContext.Provider
      value={{
        status,
        handleStatusChange,
        setStatus,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

export { TableContext, StatusProvider };
