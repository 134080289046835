import { ExternalAuthContext, SSOAuthContext } from '@src/context/AuthProvider';
import { useContext } from 'react';

const useSSOAuth = () => {
  const context = useContext(SSOAuthContext);
  if (context === undefined) {
    throw new Error('useSSOAuth must be used within an AuthProvider');
  }
  return context;
};

const useExternalAuth = () => {
  const context = useContext(ExternalAuthContext);
  if (context === undefined) {
    throw new Error('useExternalAuth must be used within an AuthProvider');
  }
  return context;
};

export { useSSOAuth, useExternalAuth };
