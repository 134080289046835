import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Badge } from '@abyss/web/ui/Badge';
import { Card } from '@abyss/web/ui/Card';
import { DataTable } from '@abyss/web/ui/DataTable';
import { Grid } from '@abyss/web/ui/Grid';
import { Router } from '@abyss/web/ui/Router';
import { getFlnStorage, setFlnStorage } from '@src/Utilities';
import { TableContext } from '@src/context/StatusProvider/StatusProvider';
import React, { useEffect, useState } from 'react';
import { tempData, updateStatus } from '../EditJson/data';
import { CustomCountdown } from './CustomCountdown';
import { getBySubmissionID, getSubmissionIDs } from '@src/apis/apis';
import { useTimer } from '@src/context/TimerProvider/TimerPovider';
import { Button } from '@abyss/web/ui/Button';
import { Popover } from '@abyss/web/ui/Popover';
import { Modal } from '@abyss/web/ui/Modal';
import { TextInput } from '@abyss/web/ui/TextInput/TextInput';
import { Layout } from '@abyss/web/ui/Layout';
const jobData = [
  {
    submissionID: '1324-13131-1313-1313',
    receivedDateTime: '',
    releaseDateTime: '8.20.24 10:32',
    timeDuration: '',
    action: 'Completed ',
    status: '',
    outcome: 'Member selected -Jane Doe, Policy 1323423, ID 999323232',
    analyst: 'Nancy Drew',
    subRows: [
      {
        submissionID: '1324-13131-1313-1313',
        receivedDateTime: '8.20.24 10:05a',
        releaseDateTime: '8.20.24 10:07',
        timeDuration: '2 minutes',
        action: 'Routed to Fallout Queue ',
        status: 'Not Started',
        outcome: '',
        analyst: 'DEAR platform auto route',
      },
      {
        submissionID: '1324-13131-1313-1313',
        receivedDateTime: '8.20.24 10:07a',
        releaseDateTime: '8.20.24 10:30a',
        timeDuration: '3 min',
        action: '',
        status: 'In Progress',
        outcome: '',
        analyst: 'Jane Brown',
      },
      {
        submissionID: '1324-13131-1313-1313',
        receivedDateTime: '8.20.24 10:30a',
        releaseDateTime: '8.20.24 10:30a',
        timeDuration: '20 mins',
        action: '',
        status: 'Hold',
        outcome: '',
        analyst: 'Jane Brown',
      },
      {
        submissionID: '1324-13131-1313-1313',
        receivedDateTime: '',
        releaseDateTime: '8.20.24 10:30',
        timeDuration: '2 mins',
        action: 'Reassigned',
        status: '',
        outcome: '',
        analyst: 'Tina Monroe',
      },
      {
        submissionID: '1324-13131-1313-1313',
        receivedDateTime: '8.20.24 10:30am',
        releaseDateTime: '8.20.24 10:32',
        timeDuration: '2 mins',
        action: '',
        status: 'In Progress',
        outcome: '',
        analyst: 'Nancy Drew',
      },
      {
        submissionID: '1324-13131-1313-1313',
        receivedDateTime: '',
        releaseDateTime: '8.20.24 10:32',
        timeDuration: '2 mins',
        action: 'Completed ',
        status: '',
        outcome: 'Member selected -Jane Doe, Policy 1323423, ID 999323232',
        analyst: 'Nancy Drew',
      },
      {
        submissionID: 'TotalDuration',
        receivedDateTime: '',
        releaseDateTime: '',
        timeDuration: '27 mins',
        action: '',
        status: '',
        outcome: '',
        analyst: '',
      },
    ],
  },
  {
    submissionID: '1324-13131-1313-1313',
    receivedDateTime: '',
    releaseDateTime: '8.20.24 10:32',
    timeDuration: '',
    action: 'Rejected',
    status: '',
    outcome: 'Member selected -Jane Doe, Policy 1323423, ID 999323232',
    analyst: 'Nancy Drew',
    subRows: [
      {
        submissionID: '1324-13131-1313-1313',
        receivedDateTime: '8.20.24 10:05a',
        releaseDateTime: '8.20.24 10:07',
        timeDuration: '2 minutes',
        action: 'Routed to Fallout Queue ',
        status: 'Not Started',
        outcome: '',
        analyst: 'DEAR platform auto route',
      },
      {
        submissionID: '1324-13131-1313-1313',
        receivedDateTime: '8.20.24 10:07a',
        releaseDateTime: '8.20.24 10:30a',
        timeDuration: '3 min',
        action: '',
        status: 'In Progress',
        outcome: '',
        analyst: 'Jane Brown',
      },
      {
        submissionID: '1324-13131-1313-1313',
        receivedDateTime: '8.20.24 10:30a',
        releaseDateTime: '8.20.24 10:30a',
        timeDuration: '20 mins',
        action: '',
        status: 'Hold',
        outcome: '',
        analyst: 'Jane Brown',
      },
      {
        submissionID: '1324-13131-1313-1313',
        receivedDateTime: '',
        releaseDateTime: '8.20.24 10:30',
        timeDuration: '2 mins',
        action: 'Reassigned',
        status: '',
        outcome: '',
        analyst: 'Tina Monroe',
      },
      {
        submissionID: '1324-13131-1313-1313',
        receivedDateTime: '8.20.24 10:30am',
        releaseDateTime: '8.20.24 10:32',
        timeDuration: '2 mins',
        action: '',
        status: 'In Progress',
        outcome: '',
        analyst: 'Nancy Drew',
      },
      {
        submissionID: '1324-13131-1313-1313',
        receivedDateTime: '',
        releaseDateTime: '8.20.24 10:32',
        timeDuration: '2 mins',
        action: 'Rejected  ',
        status: '',
        outcome: 'Member selected -Jane Doe, Policy 1323423, ID 999323232',
        analyst: 'Nancy Drew',
      },
      {
        submissionID: 'TotalDuration',
        receivedDateTime: '',
        releaseDateTime: '',
        timeDuration: '27 mins',
        action: '',
        status: '',
        outcome: '',
        analyst: '',
      },
    ],
  },
];

export const Home = () => {
  const { navigate } = useRouter();
  // const data = getSubmissionIDs();
  const { status, setStatus } = React.useContext(TableContext);

  const [activity, setActivity] = useState<string>(status || '');
  const [selectedJob, setSelectedJob] = useState(null);
  const handleReviewClick = (jobData) => {
    setSelectedJob(jobData);
  };

  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsPopoverVisible(true);
  };
  const popover = () => {
    console.log('>>popover', 'entered');

    return (
      <>
        <Popover content="Show Arrow">
          <Button>Show Arrow</Button>
        </Popover>
      </>
    );
  };
  const assignButton = [
    // {
    //   onClick: ({ row, modifyRow }) => {
    //     setActivity('InProgress');
    //     setStatus('InProgress');
    //     modifyRow(row, { status: 'InProgress' });

    //     updateStatus(row.original.submissionID, 'InProgress');
    //   },

    //   label: 'Assign',
    // },
    {
      onClick: ({ row }) => {
        navigate(`/internal/ocreditor/${row.original.submissionID}`);
      },

      label: 'Review',
    },
    {
      onClick: () => setIsOpen(true),
      label: 'Reassign',
    },
  ];
  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Submission/Job ID',
        accessor: 'submissionID',
        sortType: 'alphanumericCaseInsensitive',
      },
      {
        Header: 'File',
        accessor: 'filename',
        sortType: 'alphanumericCaseInsensitive',
      },
      {
        Header: 'Doc Class',
        accessor: 'docClass',
        sortType: 'alphanumericCaseInsensitive',
      },
      {
        Header: 'Received Date/time',
        accessor: 'receivedDateTime',
        sortType: 'alphanumericCaseInsensitive',
      },
      {
        Header: 'Target Date/time',
        accessor: 'targetDateTime',
        sortType: 'alphanumericCaseInsensitive',
      },
      // {
      //   Header: 'Completed Date Time',
      //   accessor: 'completedDateTime',
      //   sortType: 'alphanumericCaseInsensitive',
      // },
      {
        Header: 'Assigne To',
        accessor: 'assingneto',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => {
          let badgeValues;
          switch (value) {
            case 'Hold':
              badgeValues = {
                badgeLabel: value,
                variant: 'info',
              };
              break;
            case 'InProgress':
              badgeValues = {
                badgeLabel: value,
                variant: 'success',
              };
              break;
            case 'Rejected':
              badgeValues = {
                badgeLabel: value,
                variant: 'error',
              };
            case 'SLA':
              badgeValues = {
                badgeLabel: value,
                variant: 'error',
              };
              break;
            default:
              badgeValues = {
                badgeLabel: 'Not Started',
                variant: 'neutral',
              };
              break;
          }

          const { badgeLabel, variant } = badgeValues;

          return <Badge variant={variant}>{badgeLabel}</Badge>;
        },
      },
      {
        Header: 'Hold Duration',
        accessor: 'timeDuration',
        Cell: ({ row }) => {
          const { timers } = useTimer();
          // const minutes = Math.floor(time / 60);
          // const seconds = time % 60;

          return (
            <>
              {/* <CustomCountdown
                rowID={row.original.submissionID}
                status={row.original.status}
              /> */}
              {/* {minutes}:{seconds < 10 ? `0${seconds}` : seconds} */}
              {/* {timers[row.original.submissionID] || 0} */}
            </>
          );
        },
      },
    ];
  }, []);
  const recentTableColumns = React.useMemo(() => {
    return [
      {
        Header: 'Submission/Job ID',
        accessor: 'submissionID',
        sortType: 'alphanumericCaseInsensitive',
        Cell: ({ row, value }) => {
          const isSubRow = row.depth > 0;
          return (
            <div style={{ color: isSubRow ? '#003C71' : '' }}>{value}</div>
          );
        },
      },
      {
        Header: 'Received Date & Time Stamp ',
        accessor: 'receivedDateTime',
        sortType: 'alphanumericCaseInsensitive',
        Cell: ({ row, value }) => {
          const isSubRow = row.depth > 0;
          return (
            <div style={{ color: isSubRow ? '#003C71' : '' }}>{value}</div>
          );
        },
      },
      {
        Header: 'Release Date & Time Stamp',
        accessor: 'releaseDateTime',
        sortType: 'alphanumericCaseInsensitive',
        Cell: ({ row, value }) => {
          const isSubRow = row.depth > 0;
          return (
            <div style={{ color: isSubRow ? '#003C71' : '' }}>{value}</div>
          );
        },
      },

      {
        canGroupBy: true,
        Header: 'Time Duration',
        accessor: 'timeDuration',
        sortType: 'alphanumericCaseInsensitive',
        Cell: ({ row, value }) => {
          const isSubRow = row.depth > 0;
          return (
            <div style={{ color: isSubRow ? '#003C71' : '' }}>{value}</div>
          );
        },
      },
      {
        Header: 'Action',
        accessor: 'action',
        sortType: 'alphanumericCaseInsensitive',
        Cell: ({ row, value }) => {
          const isSubRow = row.depth > 0;
          return (
            <div style={{ color: isSubRow ? '#003C71' : '' }}>{value}</div>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row, value }) => {
          const isSubRow = row.depth > 0;
          return (
            <div style={{ color: isSubRow ? '#003C71' : '' }}>{value}</div>
          );
        },
      },
      {
        Header: 'Outcome',
        accessor: 'outcome',
        Cell: ({ row, value }) => {
          const isSubRow = row.depth > 0;
          return (
            <div style={{ color: isSubRow ? '#003C71' : '' }}>{value}</div>
          );
        },
      },
      {
        Header: 'Analyst/User',
        accessor: 'analyst',
        Cell: ({ row, value }) => {
          const isSubRow = row.depth > 0;
          return (
            <div style={{ color: isSubRow ? '#003C71' : '' }}>{value}</div>
          );
        },
      },
    ];
  }, []);

  const tableData = useDataTable({
    initialData: tempData,
    initialColumns: columns,
    noDataMessage: 'No results',
    showDownloadButton: false,
    individualActions: assignButton,
  });
  const recentTableData = useDataTable({
    initialData: jobData,
    initialColumns: recentTableColumns,
    noDataMessage: 'No results',
    showDownloadButton: false,
    showExpansion: true,
    // enableGroupBy: true,
    // initialState: { groupBy: ['timeDuration'] },
  });

  const [timers, setTimers] = useState(new Map()); // Map to store timer IDs
  let remainingTime;
  let timerId;
  useEffect(() => {
    tempData.forEach((record) => {
      // Load the saved end times from local storage (or your custom storage)
      const saveddata = getFlnStorage(record.submissionID);
      const savedTimerEndTimes = JSON.parse(saveddata?.timerEndTimes || '{}');
      const savedTimerEndTime = savedTimerEndTimes[record.submissionID];

      const now = Date.now();
      let delay;

      if (savedTimerEndTime && savedTimerEndTime > now) {
        // If a timer end time exists and hasn't passed, calculate the remaining time
        delay = savedTimerEndTime - now;
      } else if (!savedTimerEndTime) {
        delay = 2 * 60 * 1000;

        savedTimerEndTimes[record.submissionID] = now + delay;
        setFlnStorage(
          record.submissionID,
          'timerEndTimes',
          JSON.stringify(savedTimerEndTimes)
        );
      } else {
        return;
      }

      // Function to update the countdown
      const updateCountdown = () => {
        remainingTime = savedTimerEndTimes[record.submissionID] - Date.now();

        if (remainingTime <= 0) {
          remainingTime = 0;

          clearInterval(timerId);
        } else {
          const remainingMinutes = Math.floor(remainingTime / 1000 / 60);
          const remainingSeconds = Math.floor((remainingTime / 1000) % 60);

          if (remainingMinutes === 1 && remainingSeconds === 0) {
            // alert('Remaining time is 44 minutes');
            // setFlnStorage(record.submissionID, 'remainingStatus', String(true));
            // getBySubmissionID();
          }
          setTimeout(updateCountdown, 1000);
        }
      };

      timerId = setInterval(updateCountdown, 1000);
      timers.set(record.submissionID, timerId);

      updateCountdown();
    });

    return () => {
      // Clean up all timers when the component unmounts
      clearInterval(timerId);
    };
  }, [tempData]);

  return (
    <React.Fragment>
      <React.Fragment>
        <Router.MetaTags title="Home" />
        <React.Fragment>
          {/* <Button onClick={getBySubmissionID}>api</Button> */}
          {isPopoverVisible && (
            <Popover
              title="Popover Title Here"
              content="Popover displays secondary information when hovering over an element."
            />
          )}
          <Card collapse header="Fallout Queue">
            <Card.Section
              css={{
                'abyss-card-section': {
                  paddingLeft: '10px',
                  paddingRight: '10px',
                },
              }}
            >
              <Grid align="center" style={{ marginBottom: '10px' }}>
                {/* <Grid.Col>
                  <TextInput
                    label="Submission ID"
                    isClearable
                    type="search"
                    validators={{ required: 'Error: Enter a submission ID' }}
                  />
                </Grid.Col>
                <Grid>
                  <Grid.Col style={{ paddingTop: '38px' }}>
                    <Button
                      type="reset"
                      style={{
                        width: '120px',
                      }}
                    >
                      Reset
                    </Button>
                  </Grid.Col>
                  <Grid.Col style={{ paddingTop: '38px' }}>
                    <Button
                      style={{
                        width: '120px',
                      }}
                    >
                      Search
                    </Button>
                  </Grid.Col>
                </Grid> */}
              </Grid>
              <DataTable
                title="Search Results"
                tableState={tableData}
                css={{
                  'abyss-data-table-subheader-lower-container': { order: 1 },
                  'abyss-data-table-pagination-bottom-root': {
                    display: 'none',
                  },
                  'abyss-data-table-pagination-top-root': {
                    display: 'contents',
                  },
                  'abyss-data-table-header': { display: 'none' },
                }}
              />
            </Card.Section>
          </Card>
          <Card collapse header="Recent Submissions">
            <Card.Section
              css={{
                'abyss-card-section': {
                  paddingLeft: '10px',
                  paddingRight: '10px',
                },
              }}
            >
              <Grid align="center" style={{ marginBottom: '10px' }}>
                {/* <Grid.Col>
                  <TextInput
                    label="Submission ID"
                    isClearable
                    type="search"
                    validators={{ required: 'Error: Enter a submission ID' }}
                  />
                </Grid.Col>
                <Grid>
                  <Grid.Col style={{ paddingTop: '38px' }}>
                    <Button
                      type="reset"
                      style={{
                        width: '120px',
                      }}
                    >
                      Reset
                    </Button>
                  </Grid.Col>
                  <Grid.Col style={{ paddingTop: '38px' }}>
                    <Button
                      style={{
                        width: '120px',
                      }}
                    >
                      Search
                    </Button>
                  </Grid.Col>
                </Grid> */}
              </Grid>
              {/* <FormProvider state={form} onSubmit={onSubmit}> */}
              <Modal
                title="Enter Assigne Details"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
              >
                <Modal.Section>
                  <Grid>
                    <Grid.Col span={6}>
                      <TextInput
                        label="First Name"
                        // model="first-name"
                        validators={{ required: true }}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        label="Last Name"
                        // model="last-name"
                        validators={{ required: true }}
                      />
                    </Grid.Col>
                  </Grid>
                </Modal.Section>
                <Modal.Section>
                  <Layout.Group alignLayout="right">
                    <Button variant="outline" onClick={() => setIsOpen(false)}>
                      Cancel
                    </Button>
                    <Button variant="outline">Submit</Button>
                  </Layout.Group>
                </Modal.Section>
              </Modal>
              {/* </FormProvider> */}
              <DataTable
                title="Recent Submissions"
                tableState={recentTableData}
                css={{
                  'abyss-data-table-subheader-lower-container': { order: 1 },
                  'abyss-data-table-pagination-bottom-root': {
                    display: 'none',
                  },
                  'abyss-data-table-pagination-top-root': {
                    display: 'contents',
                  },
                  'abyss-data-table-header': { display: 'none' },
                }}
              />
            </Card.Section>
          </Card>
        </React.Fragment>
      </React.Fragment>
    </React.Fragment>
  );
};
