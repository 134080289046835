import { useRouter } from '@abyss/web/hooks/useRouter';
import { LoadingOverlay } from '@abyss/web/ui/LoadingOverlay';
import { Router } from '@abyss/web/ui/Router';
import { InteractionType } from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useMsalAuthentication,
} from '@azure/msal-react';
import { callMsGraph } from '@src/apis/apis';
import { loginRequest } from '@src/authConfig';
import { StatusProvider } from '@src/context/StatusProvider/StatusProvider';
import { TimerProvider } from '@src/context/TimerProvider/TimerPovider';
import { useSSOAuth } from '@src/hooks/useAuth';
import { Unauthorized } from '@src/routes/Unauthorized';
import React, { useEffect, useState } from 'react';

const AppWrapper = () => {
  const [graphData, setGraphData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { ssoAuth, setSSOAuth } = useSSOAuth();
  const account = useAccount();

  const { navigate } = useRouter();
  const allowedAccessGroups = ['Dear_Fallout_UI_Non_Prod_Admin'];

  const { login, result, error } = useMsalAuthentication(
    InteractionType.Redirect,
    {
      ...loginRequest,
    }
  );

  useEffect(() => {
    if (!ssoAuth.initialSignIn && account === null) {
      login(InteractionType.Redirect, { ...loginRequest });
    }

    if (graphData) {
      // We already have the data, no need to call the API
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
      return;
    }

    if (error) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
      // Error occurred attempting to acquire a token, either handle the error or do nothing
      return;
    }

    if (result) {
      callMsGraph().then((response) => {
        const isAdmin =
          response &&
          response.msad_groups?.includes('Dear_Fallout_UI_Non_Prod_Admin');
        const isSupervisor =
          response &&
          response.msad_groups?.includes('Dear_Fallout_UI_Non_Prod_Admin');
        setSSOAuth({
          ...response,
          isAuthenticated: true,
          access_token: result.accessToken,
          refresh_token: '',
          expires_on: result?.expiresOn!,
          firstName: response?.given_name,
          lastName: response?.family_name,
          initials: response?.given_name[0] + response?.family_name[0],
          email: response?.email,
          msad_groups: Array.isArray(response.msad_groups)
            ? response.msad_groups
            : [response.msad_groups],
          isAdmin,
          isSupervisor,
        });
        return setGraphData(response);
      });
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, [error, result, graphData]);

  useEffect(() => {
    if (ssoAuth.isAuthenticated) {
      if (
        window.location.pathname !== '/unauthorized' &&
        window.location.pathname !== '/logout' &&
        window.location.pathname === '/internal'
      ) {
        // Add null check for location
        navigate('/internal/home');
      }
    }
  }, [ssoAuth.isAuthenticated, window.location.pathname]); // Add null check for location

  if (isLoading) {
    return (
      <LoadingOverlay
        loadingTitle="Please Wait"
        loadingMessage="Attempting to sign in"
        ariaLoadingLabel="Attemping to sign in"
        isLoading={isLoading}
      >
        <div style={{ height: '100vh' }} />
      </LoadingOverlay>
    );
  }
  return (
    <React.Fragment>
      <AuthenticatedTemplate>
        {ssoAuth.msad_groups.some((group) => {
          return allowedAccessGroups.includes(group);
        }) ? (
          <StatusProvider>
            <TimerProvider>
              <Router.Outlet />
            </TimerProvider>
          </StatusProvider>
        ) : (
          <Unauthorized />
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Unauthorized />
      </UnauthenticatedTemplate>
    </React.Fragment>
  );
};

export { AppWrapper };
