import { Button } from '@abyss/web/ui/Button';
import { Card } from '@abyss/web/ui/Card';
import { useRouter } from '@abyss/web/hooks/useRouter';
import React from 'react';

const Logout = () => {
  const { navigate } = useRouter();
  const handleSignIn = () => {
    navigate('/internal/home');
  };

  return (
    <React.Fragment>
      <div className="logout-container">
        <Card className="custom-card-logout">
          <Card.Section className="card-content-logout">
            <h5 style={{ fontWeight: 'bold', textAlign: 'center' }}>
              "You have been successfully logged out."
            </h5>
          </Card.Section>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '10px',
            }}
          >
            <Button size="$lg" onClick={handleSignIn}>
              Sign in - via SSO
            </Button>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};

export { Logout };
