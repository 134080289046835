import { config } from '@abyss/web/tools/config';

import { graphConfig, loginRequest } from '@src/authConfig';
import { msalInstance } from '@src/browser';
import axios from 'axios';

// Get Access Token
const getTokens = async () => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw new Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }

  try {
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account,
    });

    return response.accessToken;
  } catch (error) {
    throw error;
  }
};

// SSO
const callMsGraph = async () => {
  try {
    const accessToken = await getTokens();
    const response = await axios.get(graphConfig.graphMeEndpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

// Create Authenticated Axios Instance
const createAuthenticatedAxiosInstance = (baseURL) => {
  const instance = axios.create({ baseURL });

  instance.interceptors.request.use(
    async (configuration) => {
      const accessToken = await getTokens();
      configuration.headers.Authorization = `Bearer ${accessToken}`;
      return configuration;
    },
    (error) => Promise.reject(error)
  );

  return instance;
};

const falloutIdURL =
  // 'https://dear-lab.uhg.com/fallout-service/falloutDetails';`${falloutBaseURL}?submissionId=${id}`
  'https://dear-lab.uhg.com/fallout-service/falloutDetails?submissionId=123e4567-e89b-12d3-a456-426614174000';

const falloutURL =
  'https://dear-lab.uhg.com/fallout-service/latestFalloutDetails';
const auditURL =
  'https://dear-lab.uhg.com/fallout-service/auditFalloutDetails?submissionId=123e4567-e89b-12d3-a456-426614174000';
const getBySubmissionID = async () => {
  const config = {
    method: 'get',
    url: falloutIdURL,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await axios(config); // Pass the entire config object
  return response;
};
const getSubmissionIDs = async () => {
  const config = {
    method: 'get',
    url: falloutURL,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await axios(config); // Pass the entire config object
  return response;
};
export {
  callMsGraph,
  createAuthenticatedAxiosInstance,
  getTokens,
  getBySubmissionID,
  getSubmissionIDs,
};
