import React, { useEffect } from 'react';
import { Header } from '@src/components/Header/Header';
import { OCREditor } from '@src/routes/EditJson/OCR-Editor';
import { Home } from '@src/routes/Home/Home';
import { Router } from '@abyss/web/ui/Router';
import { AppWrapper } from '@src/components/AppWrapper/AppWrapper';
import { SessionTimeout } from '@src/components/SessionTimeout/SessionTimeout';
import { useSSOAuth } from '@src/hooks/useAuth';
import { Logout } from '@src/routes/Logout';
import { Unauthorized } from '@src/routes/Unauthorized';

const AppRouter = () => {
  const { ssoAuth } = useSSOAuth();

  const [showSessionTimeout, setShowSessionTimeout] = React.useState(false);
  useEffect(() => {
    if (ssoAuth.isAuthenticated) {
      if (
        window.location.pathname !== '/' &&
        window.location.pathname !== '/unauthorized'
      ) {
        setShowSessionTimeout(true);
      }
    }
  }, [showSessionTimeout, window.location.pathname]);
  return (
    <React.Fragment>
      {/* {showSessionTimeout && <SessionTimeout />} */}
      <Router.Routes title="{{title}} | UnitedHealth Group - Fallback">
        <Router.Route path="/" element={<Header />}>
          <Router.Route path="/internal" element={<AppWrapper />}>
            <Router.Route path="home" element={<Home />} />
            <Router.Route path="ocreditor/:fln" element={<OCREditor />} />
          </Router.Route>
          <Router.Route path="/unauthorized" element={<Unauthorized />} />
          <Router.Route path="/logout" element={<Logout />} />
        </Router.Route>
      </Router.Routes>
    </React.Fragment>
  );
};
export { AppRouter };
