import { useRouter } from '@abyss/web/hooks/useRouter';
import { Button } from '@abyss/web/ui/Button';
import { Card } from '@abyss/web/ui/Card';
import { Flyout } from '@abyss/web/ui/Flyout';
import { Grid } from '@abyss/web/ui/Grid';
import { Router } from '@abyss/web/ui/Router';
import {
  getFlnStorage,
  removeFromLocalStorage,
  removeKeyFromFlnStorage,
  setFlnStorage,
} from '@src/Utilities';
import { PDFDocument } from 'pdf-lib';
import React, { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import { Breadcrumbs } from '@abyss/web/ui/Breadcrumbs/Breadcrumbs';
import { Box } from '@abyss/web/ui/Box';
import { NewTest } from './Newtest';
import { updateStatus } from './data';
import { useTimer } from '@src/context/TimerProvider/TimerPovider';

export const OCREditor = () => {
  const existinffln = window.location.pathname.split('/')[3];
  const { getRouteParams, navigate } = useRouter();

  const { fln } = getRouteParams();

  const initialsavedflndata = getFlnStorage(existinffln);
  const { stopTimer, startTimer } = useTimer();

  const [isOnHold, setIsOnHold] = useState<any>(() => {
    const savedHoldState = initialsavedflndata?.status;
    return savedHoldState ? savedHoldState : null;
  });
  const [isReset, setIsReset] = useState<boolean>(false);
  const [released, setReleased] = useState<boolean>(false);
  const [url, setUrl] = React.useState<string>('');

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.mjs`;
  const openPdf = async () => {
    const existingPdfUrl = `/dummy.pdf`;
    const existingPdfBytes = await fetch(existingPdfUrl).then((res) => {
      return res.arrayBuffer();
    });

    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    for (let i = pdfDoc.getPages().length - 1; i >= 20; i--) {
      pdfDoc.removePage(i);
    }

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    setUrl(URL.createObjectURL(blob));
  };
  useEffect(() => {
    openPdf();
  }, []);
  const onSubmit = () => {
    removeFromLocalStorage(existinffln);
    navigate('/internal/home');
  };
  const onReject = () => {
    removeFromLocalStorage(existinffln);
    removeKeyFromFlnStorage(existinffln, 'startTime');
    updateStatus(existinffln, 'Rejected');
    navigate('/internal/home');
  };

  const onReset = () => {
    setIsReset(true);
  };

  const onHold = () => {
    setReleased(true);
    setIsOnHold('Hold');
    // setFlnStorage(existinffln, 'holdState', String(true));
    updateStatus(existinffln, 'Hold');
    startTimer(existinffln);
    // setRecordStatus(existinffln, 'Hold');
  };
  const onRelease = () => {
    setReleased(false);
    setIsOnHold('Released');
    stopTimer(existinffln);

    // setRecordStatus(existinffln, 'Released');

    // setFlnStorage(existinffln, 'holdState', String(false));
  };
  useEffect(() => {
    const savedflndata = getFlnStorage(existinffln);
    const holdState = savedflndata?.status;

    if (holdState === 'Hold') {
      setReleased(true);
    } else {
      setReleased(false);
    }
  }, [existinffln, released]);

  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener('contextmenu', handleContextmenu);
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu);
    };
  }, []);
  useEffect(() => {
    const flyout = document.getElementById('flyout');
    const iframe = document.getElementById('myIframe') as HTMLIFrameElement;

    if (!flyout) return;

    const handleContextmenu = (e) => {
      e.preventDefault();
    };

    flyout.addEventListener('contextmenu', handleContextmenu);
    if (iframe) {
      iframe.onload = function () {
        const iframeDocument =
          iframe.contentWindow?.document || iframe.contentDocument;
        if (iframeDocument) {
          iframeDocument.addEventListener('contextmenu', handleContextmenu);
        }
      };
    }
    return function cleanup() {
      flyout.removeEventListener('contextmenu', handleContextmenu);
      if (iframe) {
        const iframeDocument =
          iframe.contentWindow?.document || iframe.contentDocument;
        if (iframeDocument) {
          // Add null check here
          iframeDocument.addEventListener('contextmenu', handleContextmenu);
        }
      }
    };
  }, []);
  return (
    <React.Fragment>
      <Router.MetaTags title="Edit" />
      <React.Fragment>
        <Box color="$primary2" padding="$sm">
          <Breadcrumbs
            space="16px"
            items={[
              { title: 'Home', href: '/internal/home' },
              { title: 'OCREditor', href: '/internal/ocreditor/:fln' },
            ]}
          />
        </Box>
        <Card collapse header="Edit">
          {/* <Grid>
            <Grid.Col
              span={{ xs: 6 }}
              style={{
                display: 'flex',
                padding: '30px 10px 0px 15px',
                width: '100px',
              }}
            ></Grid.Col>
            <Grid.Col
              span={{ xs: 6 }}
              style={{
                display: 'flex',
                padding: '30px 10px 0px 15px',
                width: '100px',
              }}
            ></Grid.Col>
          </Grid> */}

          <Grid>
            <Grid.Col
              span={{ xs: 12 }}
              style={{
                padding: '40px',
                overflow: 'hidden',
              }}
            >
              <NewTest disabled={isOnHold} isReset={isReset} />
            </Grid.Col>
          </Grid>
          <Grid style={{ float: 'right', padding: '20px' }}>
            <Grid.Col>
              <Button
                size="$lg"
                onClick={onReject}
                style={{
                  width: '120px',
                }}
                isDisabled={released}
              >
                Reject
              </Button>
            </Grid.Col>{' '}
            <Grid.Col>
              {released ? (
                <Button
                  size="$lg"
                  onClick={onRelease}
                  style={{
                    width: '120px',
                  }}
                >
                  Release
                </Button>
              ) : (
                <Button
                  size="$lg"
                  onClick={onHold}
                  style={{
                    width: '120px',
                  }}
                >
                  Hold
                </Button>
              )}
            </Grid.Col>{' '}
            <Grid.Col>
              <Button
                onClick={onSubmit}
                size="$lg"
                style={{
                  width: '120px',
                }}
                isDisabled={released}
              >
                Submit
              </Button>
            </Grid.Col>{' '}
            <Grid.Col>
              <Button
                type="reset"
                size="$lg"
                onClick={onReset}
                style={{
                  width: '120px',
                }}
                isDisabled={released}
              >
                Reset
              </Button>
            </Grid.Col>
          </Grid>
        </Card>
        <Flyout
          label="PDF"
          indent="20%"
          height="400px"
          width="708px"
          color="grey"
          position="bottom"
        >
          <div
            style={{
              position: 'relative',
            }}
          >
            <iframe
              src={`${url}#toolbar=0`}
              id="myIframe"
              style={{
                width: '700px',
                height: '400px',
                border: '1px solid #000',
                position: 'relative',
                scrollbarColor: 'pink',
              }}
              title="PDF"
              onContextMenu={(e) => {
                e.preventDefault();
              }}
            />
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 1,
              }}
              // onContextMenu={(e) => {
              //   e.preventDefault();
              // }}
            />
          </div>
        </Flyout>
      </React.Fragment>
    </React.Fragment>
  );
};
