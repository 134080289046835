import { createContext, useContext, useEffect, useState } from 'react';
import { setFlnStorage } from '@src/Utilities'; // Assuming this is the correct import

const TimerContext = createContext({
  timers: {}, // Store the time for each record
  isRunning: {}, // Store the running status for each record
  startTimer: (recordId) => {},
  stopTimer: (recordId) => {},
  setRecordStatus: (recordId, status) => {}, // Function to set the status of a record
});

export const useTimer = () => useContext(TimerContext);

export const TimerProvider = ({ children }) => {
  const [timers, setTimers] = useState({});
  const [isRunning, setIsRunning] = useState({});

  // Helper function to get data from localStorage for a specific record
  const getRecordData = (recordId) => {
    const data = JSON.parse(localStorage.getItem(`fln_${recordId}`) || '{}');
    return data;
  };

  // Helper function to set data in localStorage for a specific record
  const setRecordData = (recordId, data) => {
    localStorage.setItem(`fln_${recordId}`, JSON.stringify(data));
  };

  // Function to get the status of a specific record
  const getStatusForRecord = (recordId) => {
    const data = getRecordData(recordId);
    return data.status || 'Idle'; // Default to 'Idle' if no status is found
  };

  // Function to set the status of a specific record
  const setRecordStatus = (recordId, newStatus) => {
    const data = getRecordData(recordId);
    data.status = newStatus;
    if (newStatus === 'Hold') {
      data.holdStartTime = Date.now();
    } else {
      delete data.holdStartTime;
    }
    setRecordData(recordId, data);

    // Optionally store using setFlnStorage
    setFlnStorage(recordId, newStatus, data.timer || 0);
  };

  // Function to start the timer for a specific record
  const startTimer = (recordId) => {
    setIsRunning((prevRunning) => ({
      ...prevRunning,
      [recordId]: true,
    }));

    // Update the status to 'Hold'
    setRecordStatus(recordId, 'Hold');
  };

  // Function to stop the timer for a specific record
  const stopTimer = (recordId) => {
    setIsRunning((prevRunning) => ({
      ...prevRunning,
      [recordId]: false,
    }));

    // Optionally update the status to 'Stopped'
    setRecordStatus(recordId, 'Released');
  };

  // On page load, check statuses and start timers for records with 'Hold' status
  useEffect(() => {
    const initialTimers = {};
    const initialRunningState = {};

    // Loop through records (assuming you have some way to get all record IDs)
    const allRecordIds = Object.keys(localStorage).filter((key) =>
      key.startsWith('fln_')
    );

    allRecordIds.forEach((key) => {
      const recordId = key.replace('fln_', '');
      const data = getRecordData(recordId);

      if (data.status === 'Hold' && data.holdStartTime) {
        const elapsedTime = Math.floor(
          (Date.now() - data.holdStartTime) / 1000
        );
        initialTimers[recordId] = (data.timer || 0) + elapsedTime;
        initialRunningState[recordId] = true; // Start the timer if the status is 'Hold'
      }

      initialTimers[recordId] = data.timer ?? 0; // Initialize the timer value
    });

    // Set initial states
    setTimers(initialTimers);
    setIsRunning(initialRunningState);
  }, []);

  // Effect to handle the timers for each record independently
  useEffect(() => {
    const intervals = {};

    Object.keys(isRunning).forEach((recordId) => {
      if (isRunning[recordId]) {
        intervals[recordId] = setInterval(() => {
          setTimers((prevTimers) => {
            const newTime = (prevTimers[recordId] || 0) + 1;
            const updatedTimers = { ...prevTimers, [recordId]: newTime };

            // Save the updated timer to localStorage
            const data = getRecordData(recordId);
            data.timer = newTime;
            setRecordData(recordId, data);

            // Use setFlnStorage to persist the updated time

            return updatedTimers;
          });
        }, 1000);
      } else if (intervals[recordId]) {
        clearInterval(intervals[recordId]);
      }
    });

    // Cleanup intervals when component unmounts or isRunning changes
    return () => {
      Object.values(intervals).forEach(clearInterval);
    };
  }, [isRunning]);

  return (
    <TimerContext.Provider
      value={{ timers, isRunning, startTimer, stopTimer, setRecordStatus }}
    >
      {children}
    </TimerContext.Provider>
  );
};
