// import localStorage from 'react-secure-storage';

export const getFlnStorage = (existinffln: any) => {
  const storedFlnData = localStorage.getItem(`fln_${existinffln}`);
  return storedFlnData ? JSON.parse(storedFlnData as any) : {};
};
export const getkeyFlnStorage = (existinffln: any, key: string) => {
  const storedFlnData = localStorage.getItem(`fln_${existinffln}`);
  const parsedData = storedFlnData ? JSON.parse(storedFlnData as any) : {};
  return parsedData[key];
};
export const setFlnStorage = (existinffln, key, value) => {
  const existingflnData = getFlnStorage(existinffln);
  const newData = { ...existingflnData, [key]: value };
  // flnData[key] = value;
  localStorage.setItem(`fln_${existinffln}`, JSON.stringify(newData));
};
export function removeFromLocalStorage(existinffln) {
  const storageKey = `fln_${existinffln}`;
  if (localStorage.getItem(storageKey)) {
    localStorage.removeItem(storageKey);
  } else {
    console.log(`Item with key ${storageKey} does not exist in localStorage`);
  }
}
export const removeKeyFromFlnStorage = (existinffln, keyToRemove) => {
  const flnData = getFlnStorage(existinffln);
  if (flnData.hasOwnProperty.call(keyToRemove)) {
    delete flnData[keyToRemove];
    localStorage.setItem(`fln_${existinffln}`, JSON.stringify(flnData));
  } else {
    console.log(`Key '${keyToRemove}' does not exist in flnData`);
  }
};

export const removeAllForFln = (existinffln: string) => {
  const pattern = new RegExp(`^fln_${existinffln}_`);

  const allKeys = Object.keys(localStorage);

  allKeys.forEach((key) => {
    if (pattern.test(key)) {
      localStorage.removeItem(key);
    }
  });
};
