import React from 'react';
import { createTheme } from '@abyss/web/tools/theme';
import { createRouter } from '@abyss/web/tools/createRouter';
import { AbyssProvider } from '@abyss/web/ui/AbyssProvider';
import { SSOAuthProvider } from '@src/context/AuthProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import { AppRouter } from './AppRouter';

export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const { account } = payload;
    msalInstance.setActiveAccount(account);
  }
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      refetchOnWindowFocus: false,
      // retry: (failureCount, error) => {
      //   const excludedStatusCodes = [400];
      //   return (
      //     !excludedStatusCodes.includes(
      //       (error as AxiosError)?.response?.status || 0
      //     ) && failureCount < 3
      //   );
      // },
    },
    mutations: {
      retry: 3,
    },
    // retry: (failureCount, error) => {
    //   const excludedStatusCodes = [400];
    //   return (
    //     !excludedStatusCodes.includes(
    //       (error as AxiosError)?.response?.status || 0
    //     ) && failureCount < 3
    //   );
    // },
  },
});

const theme = createTheme('uhg');
const router = createRouter(AppRouter);

export const browser = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <SSOAuthProvider>
          <AbyssProvider theme={theme} router={router} />
        </SSOAuthProvider>
      </QueryClientProvider>
    </MsalProvider>
  );
};
