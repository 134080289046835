/* eslint-disable no-console */
import {
  BrowserCacheLocation,
  Configuration,
  LogLevel,
  ProtocolMode,
  SilentRequest,
} from '@azure/msal-browser';
import { config } from '@abyss/web/tools/config';

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const isBrowser = () => {
  return typeof window !== 'undefined';
};
const ua = isBrowser() ? window.navigator.userAgent : '';
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
const msalConfig: Configuration = {
  auth: {
    protocolMode: ProtocolMode.OIDC,
    redirectUri: config('REDIRECT_URI'),
    clientId: config('CLIENT_ID') || '',
    authority: config('SSO_BASE_URL'),
    knownAuthorities: [`${config('SSO_BASE_URL')}`],
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        if (process.env.NODE_ENV === 'production') {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            break;
          default:
        }
      },
    },
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
const loginRequest: SilentRequest = {
  scopes: ['openid', 'profile', 'address', 'email', 'phone'],
  extraQueryParameters: { acr_values: 'R1_AAL1_MS-AD-Kerberos' },
};

// Add here the endpoints for MS Graph API services you would like to use.
const graphConfig = {
  graphMeEndpoint:
    'https://authgateway1-dev.entiam.uhg.com/idp/userinfo.openid',
};

export { msalConfig, loginRequest, graphConfig };
// https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/samples/msal-react-samples/react-router-sample
