import { useRouter } from '@abyss/web/hooks/useRouter';
import { dayjs } from '@abyss/web/tools/dayjs';
import { Avatar } from '@abyss/web/ui/Avatar';
import { Box } from '@abyss/web/ui/Box';
import { Brandmark } from '@abyss/web/ui/Brandmark';
import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { Grid } from '@abyss/web/ui/Grid';
import { Link } from '@abyss/web/ui/Link';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';
import { Router } from '@abyss/web/ui/Router';
import { msalInstance } from '@src/browser';
import { useSSOAuth } from '@src/hooks/useAuth';
import React, { useEffect } from 'react';
import { StyledDrawer } from '../StyledDrawer/StyledDrawer';

const Header = () => {
  const { ssoAuth, setSSOAuth } = useSSOAuth();
  const { navigate } = useRouter();

  const handleLogout = () => {
    setSSOAuth({
      isAuthenticated: false,
      initialSignIn: false,
      access_token: '',
      refresh_token: '',
      expires_on: dayjs('0000-00-00T00:00:00.000Z'),
      username: '',
      title: '',
      firstName: '',
      lastName: '',
      initials: '',
      email: '',
      department: '',
      division: '',
      msad_groups: [],
      isAdmin: false,
      isSupervisor: false,
    });

    msalInstance.setActiveAccount(null);
    msalInstance.clearCache();
    navigate('/logout');
  };
  useEffect(() => {
    if (!ssoAuth.isAuthenticated) {
      if (window.location.pathname === '/') {
        navigate('/internal');
      }
    }
  }, [ssoAuth.isAuthenticated]);

  const menuItems = [
    {
      title: 'Logout',
      onClick: handleLogout,
    },
  ];
  return (
    <React.Fragment>
      <Grid
        style={{
          margin: 0,
          overflow: 'hidden',
        }}
      >
        {/* Nav */}
        <Grid.Col
          span={{ xs: '100%' }}
          style={{
            padding: 0,
          }}
        >
          <Box color="$primary1" padding="5px">
            <Grid justify="space-between" align="center">
              {/* Nav Menu + Logo  */}
              <Grid.Col>
                <Grid>
                  {/* Nav Menu */}
                  <Grid.Col>
                    <StyledDrawer />
                  </Grid.Col>
                  {/* Logo */}
                  <Grid.Col>
                    <Box
                      color="$primary1"
                      width="250px"
                      padding="15px 0px 0px 0px"
                    >
                      <Brandmark
                        brand="uhg"
                        affiliate="uhg"
                        variant="lockup"
                        color="white"
                      />
                    </Box>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
              {/* Links + Account Circle  */}
              <Grid.Col>
                <Grid>
                  {/* Links  */}
                  {ssoAuth.isAuthenticated && (
                    <MediaQuery largerThan="$sm">
                      <Grid.Col>
                        <Grid>
                          <Grid.Col style={{ paddingTop: 22 }}>
                            <Link
                              color="$primary2"
                              colorHover="$interactive3"
                              href="/internal/home"
                            >
                              Home
                            </Link>
                          </Grid.Col>
                          {/* <Grid.Col style={{ paddingTop: 22 }}>
                            <Link
                              color="$primary2"
                              colorHover="$interactive3"
                              href="/internal/upload"
                            >
                              Upload
                            </Link>
                          </Grid.Col> */}
                        </Grid>
                      </Grid.Col>
                    </MediaQuery>
                  )}
                  {/* Account Circle */}
                  <Grid.Col>
                    <DropdownMenu
                      hideLabel
                      variant="filled"
                      after={
                        <Avatar>
                          {ssoAuth.initials !== '' ? ssoAuth.initials : null}
                        </Avatar>
                      }
                      menuItems={ssoAuth.username !== '' ? menuItems : []}
                      width={150}
                      label={undefined}
                    />
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          </Box>
        </Grid.Col>
      </Grid>

      <Box color="$primary2" padding="$sm">
        <Router.Outlet />
      </Box>
    </React.Fragment>
  );
};

export { Header };
