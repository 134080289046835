export const tempData = [
  {
    fln: '11',
    filename: '65f3313570c8e52a2.txt',

    submissionID: '1',

    docClass: 'TRACR',
    status: 'NOT STARTED',
    completedBy: 'Jane Doe',
    receivedDateTime: '2024/01/18',
    targetDateTime: '2024/01/18',
    completedDateTime: '2024/01/18',
    member1: {
      id: '1',
      memberID: '1987',
      memberLastName: 'ted',
      policyNumber: '123456',
      dateOfBirth: '01/01/1990',
      dateOfService: '01/01/2021',
      editing: false,
      govcode: '12999993',
      mstate: 'MN',
      sourcecode: 'CDB',
      pedate: '02/02/2024',
    },
    member2: {
      id: '1',
      memberID: '25',
      memberLastName: 'rob',
      policyNumber: '123456',
      dateOfBirth: '01/01/1990',
      dateOfService: '01/01/2021',
      editing: false,
      govcode: '9000123',
      mstate: 'MN',
      sourcecode: 'CDB',
      pedate: '02/02/2024',
    },
  },
  {
    fln: '22',
    filename: '652.txt',

    submissionID: '2',

    docClass: 'TRACR',
    status: 'HOLD',
    completedBy: 'Jane Doe',
    receivedDateTime: '2024/01/18',
    targetDateTime: '2024/01/18',
    completedDateTime: '2024/01/18',
    member1: {
      id: '1',
      memberID: '1234567890',
      memberLastName: 'jack',
      policyNumber: '123456',
      dateOfBirth: '01/01/1990',
      dateOfService: '01/01/2021',
      editing: false,
      govcode: '123',
      mstate: 'MN',
      sourcecode: 'CDB',
      pedate: '02/02/2024',
    },
    member2: {
      id: '1',
      memberID: '22',
      memberLastName: 'nova',
      policyNumber: '123456',
      dateOfBirth: '01/01/1990',
      dateOfService: '01/01/2021',
      editing: false,
      govcode: '123',
      mstate: 'MN',
      sourcecode: 'CDB',
      pedate: '02/02/2024',
    },
  },
];

export function getMembersByFln(submissionID: string) {
  const members: { [key: string]: any }[] = [];

  for (const item of tempData) {
    if (item.submissionID === submissionID) {
      // Iterate over all properties of the item
      for (const property in item) {
        // Check if the property starts with 'member'
        if (property.startsWith('member')) {
          members.push(item[property]);
        }
      }
    }
  }

  return members; // return an array of members
}
export function updateStatus(submissionID: string, newStatus: string) {
  // Iterate over all items in tempData
  for (let i = 0; i < tempData.length; i++) {
    if (tempData[i].submissionID === submissionID) {
      // Update the status of the item
      tempData[i].status = newStatus;
      break;
    }
  }
}
